<template>
  <div id="app">
    <Navbar></Navbar>
    <div class="contents">
      <br>
        <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
    name: "app",
    components: {
        Navbar,
    },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

/* Handle reseting style conflicts with ULabel */
#container {
  line-height: initial;
}

#container p {
  margin-block: initial;
}

#container label {
  margin: initial;
}

#container input[type=checkbox] {
  transform: initial;
}

#container div.recolor-active input[type=checkbox] {
  margin-left: 0.25rem;
}

#container div.recolor-active div.gradient-toggle-container {
  margin-bottom: 0.25rem;
}

#container fieldset {
  margin: initial;
  padding: 4px;
  padding-top: 0;
  border: 1px solid black;
  border-radius: 2px;
}

#container legend {
  font-size: initial;
  width: initial;
  max-width: initial
}

/* Directly reset normally inherited property */
#container fieldset.filter-row-distance-options > legend {
  font-size: 100%;
}

#container div.toolbox-class-counter > p:last-child {
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

</style>
