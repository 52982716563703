<template>
    <div>
        <div class="base-grouping">
            <h3>Analytic Details</h3>
            <table class="details-table">
                <tr>
                    <td class="label-column">Analytic ID:</td>
                    <td class="data-column">{{ analyticID }}</td>
                </tr>
                <tr>
                    <td class="label-column">Organization Name:</td>
                    <td class="data-column">{{ orgName }}</td>
                </tr>
                <tr>
                    <td class="label-column">Field Name:</td>
                    <td class="data-column">{{ fieldName }}</td>
                </tr>
                <tr>
                    <td class="label-column">Crop Type:</td>
                    <td class="data-column">{{ cropType }}</td>
                </tr>
                <tr>
                    <td class="label-column">Run Type:</td>
                    <td class="data-column">{{ runType }}</td>
                </tr>
                <tr>
                    <td class="label-column">Sentera ID:</td>
                    <td class="data-column">
                        <a :href="getActiveAdminURL()" target="_blank">{{ senteraID }}</a>
                    </td>
                </tr>
                <tr>
                    <td class="label-column">Planting Pattern:</td>
                    <td class="data-column">{{ plantingPattern }}</td>
                </tr>
                <tr>
                    <td class="label-column">Created At:</td>
                    <td class="data-column">{{ createdAt }}</td>
                </tr>
            </table>
            <div class="sub-grouping" :style="{ backgroundColor: statusColor }">
                    <p class="input-label" style="font-weight:500">Status</p>
                    <select v-model="status" id="status-select">
                        <option value="completed">Completed</option>
                        <option value="rejected">Abandoned</option>
                        <option value="not_done">Not Done</option>
                    </select>
                </div>
            <div class="sub-grouping">
                <p class="input-label">Default Keypoint Confidence (%)</p>
                
                <div class="slider-container">
                    <span class="slider-value">{{ confidenceSlider }}</span>
                    <input type="range" v-model="confidenceSlider" min="0" max="100" />
                </div>

                <p class="input-label">Default Row Distance (pixels)</p>
                
                <div class="slider-container">
                    <span class="slider-value">{{ rowDistanceSlider }}</span>
                    <input type="range" v-model="rowDistanceSlider" min="0" max="300" />
                </div>
                <p class="input-label">Image Buffer (%)</p>
                
                <div class="slider-container">
                    <span class="slider-value">{{ imageBuffer }}</span>
                    <input type="range" v-model="imageBuffer" min="0" max="100" />
                </div>

                <div class="checkbox-container" style="margin-top: 20px">
                    <input type="checkbox" v-model="outsourcing" />
                    <label class="input-label">Outsourcing</label>
                </div>

                <div class="priority-container">
                    <label class="input-label">Priority</label>
                    <input type="number" v-model="priority" min="1" max="100" />
                </div>

                <button @click="saveUpdates" style="margin-top: 30px">Save Updates</button>
            </div>
        </div>
        <div class="base-grouping" style="margin-top: 30px">
            <h3 style="margin-bottom: 30px">Export</h3>
            <div class="sub-grouping">
                <div class="checkbox-container">
                    <input type="checkbox" v-model="rowfillFlag" />
                    <label class="input-label"> Rowfill </label>
                </div>

                <div class="checkbox-container">
                    <input type="checkbox" v-model="rejectedFlag" />
                    <label class="input-label"> Include Rejected </label>
                </div>

                <button @click="runExport">Export</button>
            </div>
        </div>
        <div class="dropdown-container base-grouping">
            <div class="dropdown-header" @click="toggleDropdown">
                <span>Full Analytic JSON</span>
                <span :class="{'arrow-down': dropdownIsOpen, 'arrow-right': !dropdownIsOpen}">▼</span>
            </div>

            <div v-show="dropdownIsOpen" class="dropdown-content">
                <code style="text-align: left;">
                    <pre>{{JSON.stringify(fieldJSON, null, 4)}}</pre>
                </code>
            </div>
        </div>
    </div>
</template>

<script>
import { getAnalytic, patchAnalytic, exportAnalytic } from '../api/analytics';

export default {
    data() {
        return {
            fieldJSON: null,
            analyticID: null,
            fieldName: null,
            orgName: null,
            cropType: null,
            runType: null,
            senteraID: null,
            status: null,
            confidenceSlider: null,
            rowDistanceSlider: null,
            imageBuffer: null,
            outsourcing: null,
            priority: null,
            rejectedFlag: true,
            rowfillFlag: false,
            dropdownIsOpen: false, 
            plantingPattern: null,
            createdAt: null
        };
    },
    async mounted() {
        // Make API calls first before setting values so everything loads at the same time
        let analyticID = this.$store.state.$analytic_id;
        this.fieldJSON = this.$store.state.$field_json;
        let analytic_info = await getAnalytic(analyticID);

        this.analyticID = analyticID;
        this.fieldName = analytic_info.field_name
        this.orgName = analytic_info.organization_name;
        this.cropType = analytic_info.crop_type;
        this.runType = analytic_info.stand_type;
        this.senteraID = analytic_info.sentera_id;
        this.status = analytic_info.status;

        const date = new Date(analytic_info.created_at);
        this.createdAt = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });

        this.confidenceSlider = analytic_info.keypoint_slider_default
        this.rowDistanceSlider = analytic_info.row_distance_slider_default
        this.imageBuffer = analytic_info.image_buffer_pct
        this.outsourcing = analytic_info.outsourced
        this.priority = analytic_info.priority

        if (this.runType == "male_female") {
          let male_row_count = this.fieldJSON["order"]["settings"]["male_row_count"]
          let female_row_count = this.fieldJSON["order"]["settings"]["female_row_count"]
          this.plantingPattern = `${female_row_count} : ${male_row_count}`
        } else {
          this.plantingPattern = "N/A"
        }
    },
    computed: {
        statusColor() {
            switch (this.status) {
            case 'completed':
                return '#28a745'; // Green
            case 'rejected':
                return '#d2042d'; // Red 
            default:
                return '#f9f9f9'; // Default background color
            }
        }
    },
    methods: {
        async runExport() {
          await exportAnalytic(this.analyticID, this.rowfillFlag, this.rejectedFlag);
        },
        async saveUpdates() {
            await patchAnalytic(
                this.analyticID, 
                this.outsourcing, 
                this.confidenceSlider, 
                this.rowDistanceSlider, 
                this.imageBuffer, 
                this.status,
                this.priority,
            )
        },
        toggleDropdown() {
            this.dropdownIsOpen = !this.dropdownIsOpen; // Toggle the dropdown state
        },
        getActiveAdminURL() {
            let env = process.env.VUE_APP_API_ENV === "prod" ? "" : process.env.VUE_APP_API_ENV;
            return `https://admin${env}.sentera.com/admin/search?sentera_id=${this.senteraID}`;
        }
    }
}
</script>

<style scoped>
/* General */
.base-grouping {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sub-grouping {
  width: 600px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin: auto;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

h3 {
    margin-bottom: 20px;
}

/* Buttons */
button {
  padding: 10px 20px;
  font-size: 1.2em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Analytic Details Table */
.details-table {
  width: 600px;
  border-collapse: collapse;
  margin: auto;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
}

.details-table td {
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
}

.label-column {
  background-color: #f0f0f0;
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
}

.data-column {
  text-align: left;
  width: 100%;
}

/* Status Styling */
#status-select {
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

#status-select:hover {
  border-color: #007bff;
}

#status-select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Input Fields */
.input-label {
  font-size: 16px;
}

/* Slider Bars */
.slider-container {
  display: flex;
  align-items: center;
  margin: auto;
  width: 80%;
  margin-bottom: 10px;
}

.slider-value {
  width: 30px;
  text-align: left;
}

input[type="range"] {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background: #dcdcdc;
}

/* Checkboxes */
.checkbox-container {
    margin: 10px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

/* Priority Input */
.priority-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.priority-container label {
  margin-right: 10px;
}

input[type="number"] {
  width: 80px;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
}

input[type="number"]:hover {
  border-color: #007bff;
}

input[type="number"]:focus {
  border-color: #007bff;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.3);
}

/* JSON Dropdown */
.dropdown-container {
  width: 800px;
  padding: 10px;
  margin: 30px auto 50px;
  text-align: center;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.dropdown-content {
  margin-top: 10px;
  padding-left: 10px;
  border-left: 2px solid #ccc;
  text-align: left;
  max-height: 600px;
  overflow-y: auto;
}

.arrow-right {
  transform: rotate(90deg);
}

.arrow-down {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

</style>
