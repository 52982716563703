/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'
import ErrorComponent from '@/components/Error';
import UploadComponent from '@/components/Upload';
import PlantCountQA from '@/components/PlantCountQA';
import RowQA from '@/components/RowQA';
import Progress from '@/components/Progress';
import Grid from '@/components/GridViewer';
import RejectPPA from '@/components/RejectPPA';
import RejectRow from '@/components/RejectRow';
import GenderQA from '@/components/GenderQA';
import FieldOverview from '@/components/FieldOverview';
import AnalyticDetails from '@/components/AnalyticDetails.vue';

import { EXTERNAL_USER_ALLOWED_ROUTE_NAMES } from '@common/app/constants';
import { isInternalUser } from '@common/app/cognito';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/error', component: ErrorComponent
        },
        {
            path: '/upload',
            name: "Upload",
            component: UploadComponent
        },
        {
            path: '/plant_count_qa',
            name: "PPA QA",
            component: PlantCountQA,
        },
        {
            path: '/rejectPPA',
            name: "RejectPPA",
            component: RejectPPA
        },
        {
            path: '/rejectRow',
            name: "RejectRow",
            component: RejectRow
        },
        {
            path: '/row_qa', 
            name: "Row QA", 
            component: RowQA, 
        },
        {
            path: '/progress', 
            name: "Progress",
            component: Progress
        },
        {
            path: '/grid',
            name: "Grid",
            component: Grid
        },
        {
            path: '/gender_qa', 
            name: "Gender QA",
            component: GenderQA,
        },
        {
            path: '/field_overview',
            name: "Field Overview",
            component: FieldOverview
        },
        {
            path: '/analytic_details',
            component: AnalyticDetails,
            name: "Analytic Details"
        }

    ],
})

// Navigation guard to prevent unauthorized access to pages
router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && !localStorage.getItem('user')) {
        // Redirect to login page if user is not logged in
        next({ name: 'Login' })
    } else if (
        localStorage.getItem('user') && 
        !isInternalUser() &&
        !EXTERNAL_USER_ALLOWED_ROUTE_NAMES.includes(to.name) 
    ) {
        // Prevent navigation to any page other than PPA QA if user is not Internal
        alert("You are not authorized to access this page.");
        next({ name: 'Home' });
    } 
    else next()
})

export default router
