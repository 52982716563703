<template>
    <div style="height: 66px">
        <b-navbar toggleable="sm" type="light" variant="light">
            <b-navbar-toggle target="nav-text-collapse"></b-navbar-toggle>
            <b-navbar-brand href="/">
                <img src="@common/assets/sentera-logo.png" style="width:50px;height:50px">
                Stand QA Tool
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Navbar for Internal Users -->
                <b-navbar-nav id="internal-navbar" v-bind:class="internalNavbarClass()">
                    <b-nav-item href="/login">Logout</b-nav-item>
                    <b-nav-item href="/upload">Upload</b-nav-item>
                    <b-nav-item href="/field_overview">Field Overview</b-nav-item>
                    <b-nav-item href="/row_qa">Row QA</b-nav-item>
                    <b-nav-item href="/plant_count_qa">Plant Count QA</b-nav-item>
                    <b-nav-item href="/gender_qa">Gender QA</b-nav-item>
                    <b-nav-item href="/progress">Progress</b-nav-item>
                    <b-nav-item href="/grid">Grid</b-nav-item>
                    <b-nav-item href="/analytic_details">Analytic Details</b-nav-item>
                </b-navbar-nav>
                <!-- Navbar for External Users -->
                <b-navbar-nav id="external-navbar" v-bind:class="externalNavbarClass()">
                    <b-nav-item href="/login">Logout</b-nav-item>
                    <b-nav-item href="/plant_count_qa">Plant Count QA</b-nav-item>
                </b-navbar-nav>
                <!-- Hacky navbar items for rejection, image info, etc -->
                <b-navbar-nav id="controls-navbar">
                    <b-nav-item href="/rejectPPA" v-if="is_ppa_qa">Reject</b-nav-item>
                    <b-nav-item href="/rejectRow" v-if="is_row_qa">Reject</b-nav-item>
                    
                    <!--This is really poor practice-->
                    <div id="navbar-right-div" v-if="is_ppa_qa">
                        <p id="navbar-p">
                            Subimage  {{this.subimage_idx + 1}} of {{this.n_h_subimages * this.n_w_subimages}}, {{this.image_name}} &nbsp;
                        </p>
                        <p id="qa_status"></p>
                    </div>
                    <div id="navbar-right-div2" v-if="is_row_qa">
                        <p id="navbar-p">
                            &nbsp;&nbsp;Image {{this.image_name}}
                        </p>
                        <p v-if="gender_qa">
                            Gender QA Mode
                        </p>
                        <p v-else>
                            Row QA Mode
                        </p>
                    </div>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { isInternalUser } from '@common/app/cognito'

export default defineComponent({
    data() {
        return {
            subimage_idx: -1,
            image_name: "",
            n_h_subimages: -1,
            n_w_subimages: -1,
            gender_qa: false,
        }
    },
    computed: {
      is_ppa_qa() {    
        // If no image_name passed in route params, use store value
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "image_name")) {
            this.image_name = this.$route.params.image_name;
            this.subimage_idx = this.$route.params.subimage_idx;
            this.n_h_subimages = this.$route.params.n_h_subimages;
            this.n_w_subimages = this.$route.params.n_w_subimages;
        } else {
            this.image_name = this.$store.state.$image_name;
            this.subimage_idx = 0; // Start at first subimage
            this.n_h_subimages = this.$store.state.$n_h_subimages;
            this.n_w_subimages = this.$store.state.$n_w_subimages;
        }

        return this.$route.name === "PPA QA";
      },
      is_row_qa() {
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "gender_qa")) {
            this.gender_qa = this.$route.params.gender_qa;
        }
        return this.$route.name === "Row QA";
      }
    },
    methods: {
        goToReject() {
            // On 'reject' button press, navigate to Reject
            // page to handle modifying the field_json

            console.log("Subimage " + (this.subimage_idx + 1) + " Rejected");

            // Done when idx + 1 equals total number of subimages
            var image_done = this.$route.params.subimage_idx + 1 == this.n_h_subimages * this.n_w_subimages;
            
            if (image_done) {
                // Done with the image, query next image.
                console.log("Done with current image.");
            }
            
            // Go to reject page, pass the current subimage
            this.$router.push({
                name: "Reject",
                params: {
                    field_name: this.$store.state.$field_name,
                    image_name: this.image_name,
                    subimage_idx: this.$route.params.subimage_idx,
                    n_h_subimages: this.n_h_subimages,
                    n_w_subimages: this.n_h_subimages,
                    image_done: image_done
                }
            });
        },
        internalNavbarClass() {
            return isInternalUser() ? "" : "hidden";
        },
        externalNavbarClass() {
            return isInternalUser() ? "hidden" : "";
        }
    }
})
</script>

<style scoped>
    p { 
        margin: 0;
        font-size: 13px; 
    }

    .hidden {
        display: none;
    }
</style>
