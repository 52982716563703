import { api_consolidator, api_consolidator_error_handler } from "./utils";


export async function getAnalytics() {
    return await api_consolidator_error_handler("GET", "analytics", "items")
}

export async function patchAnalytic(
    analyticId, 
    outsourced = null, 
    keypoint_slider_default = null, 
    row_distance_slider_default = null, 
    image_buffer_pct = null, 
    status = null,
    priority = null,
) {
    let data = {
        outsourced: outsourced,
        keypoint_slider_default: keypoint_slider_default,
        row_distance_slider_default: row_distance_slider_default,
        image_buffer_pct: image_buffer_pct,
        status: status,
        priority: priority
    }

    return await api_consolidator_error_handler("PATCH", `analytics/${analyticId}`, null, null, data)
}

export async function getAnalytic(analyticID) {
    return await api_consolidator_error_handler("GET", `analytics/${analyticID}`)
}

export async function getAnalyticJson(analyticID) {
    return await api_consolidator_error_handler("GET", `analytics/${analyticID}/json`)
}

export async function exportAnalytic(analyticID, rowfill, include_rejected) {
    let params = {
        rowfill: rowfill,
        include_rejected: include_rejected
    }

    try {
        await api_consolidator("POST", `analytics/${analyticID}/deliverables`, null, params);
        alert("Export Started");
    } catch (error) {
        if (error.response) {
            alert("Failed to start export: " + error.response.data.detail);
            console.error(error.response);
        } else {
            alert("Failed to start export: " + error.message);
            console.error(error.message);
        }
    }
} 

export async function uploadAnalytic(json_s3_path) {
    try {
        await api_consolidator("POST", "analytics", null, {json_s3_path: json_s3_path});
        alert("Uploading Analytic");
    } catch(error) {
        alert("Error Uploading Analytic");
        console.error(error);
    }
}
