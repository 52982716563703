// Loaders for annotations
import axios from 'axios';

export async function get_field_tags(field_json) {
    var params, data;
    // Get all field tags so we can pass them into 
    // processes as needed
    params = {
        "field_json": field_json,
    };

    data = await axios({
        method: "post",
        url: process.env.VUE_APP_API_URL + "/get_all_field_tags",
        data: params,
    });
    console.log(data.data);
    return(data.data);
}

export async function get_subimage_qa_status(field_name, field_json, image_name) {
    var params = {
        field_json: field_json,
        image_name: image_name,
    }

    var data = await axios({
        method: "post",
        url: process.env.VUE_APP_API_URL + "/get_subimage_qa_status",
        data: params,
    });

    return data.data.status
}

export function get_field_group(field_json) {
    // Get field group from field json.
    return "All"
}
   