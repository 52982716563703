<template>
    <div>
        <div id="navbar-back-next">
            <input type="button" id="navbar-back-button" value="Back" v-on:click="go_without_saving(-1)" />
            <input type="button" id="navbar-next-button" value="Next" v-on:click="go_without_saving(1)" />&nbsp;
            <input type="button" id="navbar-break-button" value="Unlock and Exit" v-on:click="take_a_break()" />
            <input type="button" id="navbar-submit-break-button" value="Submit and Exit" v-on:click="take_a_break(true)" />
            <input type="button" id="navbar-submit-all-button" value="Submit All Subimages" v-on:click="submit_all()" />
        </div>
        <div 
            id="container" 
            style="
                width: 100%;
                height: calc(100vh - 66px);
                position: absolute;
                top: 66px;
                left: 0;"
        />
    </div>
</template>

<script>
import { 
    get_all_field_tags, 
    get_binary_url, 
    get_next_image_to_qa, 
    get_next_outsourced_image_to_qa,
    get_single_image_tags,   
} from "@common/app/api_endpoints.js";
import { 
    ULABEL_INITIAL_LINE_SIZE, 
    ULABEL_SUBTASK_IDS, 
} from "@common/app/constants.js";
import { 
    gendered_row_qa_subtask,
    get_config_data, 
    get_slider_values_from_ulabel_html,
    ppa_qa_subtask,
    subimage_bounds_subtask 
} from "@common/app/ulabel_utils.js";
import { ULabel } from "ulabel"
import { extend_line, extend_all } from "@common/app/ulabel_extend_line";
import { get_subimage_qa_status } from "@common/app/ddb_utils.js";
import { get_image_and_annotations, getMeta } from "@common/app/annotation_loaders.js";
import { hit_terraform_api } from '@common/app/api_utils.js';
import { lock_image, unlock_image, get_username } from "@common/app/qa_utils.js";
import { get_subimage_crop_from_idx, get_ulabel_initial_crop_from_bbox, make_ulabel_bbox } from "@common/app/ulabel_anno_utils.js";
import { isInternalUser } from "@common/app/cognito.js";

export default {
    data() {
        return {
            field_name: "",
            field_json: null,
            image_name: "",
            subimage_idx: -2,
            ulabel: null,
            rgb_image_url: "",
            binary_image_url: "",
            rgb_displayed: true,
            qa_status: [], // list of all subimage qa statuses
            subimage_qa_status: "",
            image_qa_status: "",
            image_tags: "",
            row_annotations: [],
            n_h_subimages: 3,
            n_w_subimages: 3,
            n_subimages: 9,
            leave_qa_cycle: false,
            submit_all_subimages: false,
            field_tags: {},
            buffer_pct: 0,
        };
    },
    async mounted() {
        // Add event listeners
        document.addEventListener("keydown", this.keydownEventHandler);

        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;

        if (Object.prototype.hasOwnProperty.call(this.$route.params, "image_name")) {
            // If image_name is in the route params, we use it
            this.image_name = this.$route.params.image_name;
            this.subimage_idx = this.$route.params.subimage_idx;
        } else {  
            // If no image_name is in the route params, we get the next available
            await this.load_next_image();
        }

        // Build top buttons
        this.build_back_and_next_buttons();

        // Check qa_status for current subimage
        await this.load_and_run_all();
    },
    methods: {
        keydownEventHandler(e) {
            extend_line(e, this.ulabel, "row_classification");
            extend_all(e, this.ulabel, "row_classification");
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    this.ulabel.swap_frame_image(this.binary_image_url);
                } else {
                    this.ulabel.swap_frame_image(this.rgb_image_url);
                }
                this.rgb_displayed = !this.rgb_displayed;
            }
            if (e.key == "Enter") {
                document.getElementById("submit").click();
            }
        },
        navigate_away() {
            this.beforeDestoryHandler();

            let dest = isInternalUser() ? "Progress" : "Home";
            this.$router.push({
                name: dest,
            });
        },
        display_qa_status() {
            this.subimage_qa_status = this.qa_status[this.subimage_idx]["keypoint_qa"];
            document.getElementById("qa_status").innerHTML = "Plant QA Status: " + this.subimage_qa_status;
        },
        build_back_and_next_buttons() {
            // Attach the buttons to the navbar
            let navbar = document.getElementById("controls-navbar");
            navbar.appendChild(document.getElementById("navbar-back-next"));
        },
        beforeDestoryHandler() {
            // Remove event listeners (including ULabel's)
            document.removeEventListener("keydown", this.keydownEventHandler);
            if (this.ulabel) {
                this.ulabel.remove_listeners();
            }
            // Remove the buttons
            document.getElementById("navbar-back-next").remove();
        },
        async take_a_break(submit = false) {
            if (submit) {
                this.leave_qa_cycle = true;
                document.getElementById("submit").click();
            } else {
                // Unlock image and navigate away
                await unlock_image(this.field_json, this.image_name);
                this.navigate_away();
            }
        },
        submit_all() {
            this.submit_all_subimages = true;
            document.getElementById("submit").click();
        },
        go_without_saving(idx_change) {
            // Navigate to previous image
            var new_subimage_idx = this.subimage_idx + idx_change;

            // Prevent out of bounds navigation
            if (new_subimage_idx < 0 || new_subimage_idx >= this.n_subimages) {
                console.log("Prevent out of bounds navigation");
            } else {
                // TODO find new way to prevent nav when page is dirty
                this.subimage_idx = new_subimage_idx;
                this.update_store();
                this.reload_ulabel();
            }

        },
        update_store() {
            // Store values in case of reject
            this.$store.commit("update$field_json", this.field_json);
            this.$store.commit("update$image_name", this.image_name);
            this.$store.commit("update$subimage_idx", this.subimage_idx);
            this.$store.commit("update$n_h_subimages", this.n_h_subimages);
            this.$store.commit("update$n_w_subimages", this.n_w_subimages);
        },

        /**
         * Load the next image to QA
         * 
         */
        async load_next_image() {
            let next_image;
            if (isInternalUser()) {
                next_image = await get_next_image_to_qa(
                    this.field_json,
                    "",
                    "keypoint_qa",
                )
            } else {
                // External users only get outsourced images
                next_image = await get_next_outsourced_image_to_qa()
            }
            console.log(next_image);
            // TODO better constants
            if (next_image == "Nothing to QA.") {
                this.navigate_to_dest_component();
            }
            this.image_name = next_image.image_name;
            this.subimage_idx = next_image.subimage_idx;
        },

        /**
         * Load all data and run ULabel
         * 
         */
        async load_and_run_all(reload_ulabel = false) {
            // Kick off all the async functions
            let lock_acq_prom = lock_image(this.field_json, this.image_name);
            let image_tags_promise = get_single_image_tags(this.field_json, this.image_name);
            let field_tags_promise = get_all_field_tags(this.field_json);
            let subimage_qa_status_promise = get_subimage_qa_status(
                this.field_name, this.field_json, this.image_name
            );
            let image_promise = this.get_image();

            this.update_store();

            // Wait for lock to be acquired, exit if not 
            let lock_acquired = await lock_acq_prom;
            if (!lock_acquired) {
                alert("Image is locked by another user. Please try again later.");
                this.navigate_away();
            }

            // Await other data
            let data = await subimage_qa_status_promise;
            this.qa_status = data["subimages"];
            this.image_qa_status = data["ppa_qa"];
            this.display_qa_status();

            // Get image tags and field tags
            this.image_tags = await image_tags_promise;
            this.field_tags = await field_tags_promise;
            try {
                this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
            } catch (e) {
                console.log("Error getting buffer_pct:", e);
            }
            console.log("Buffer pct:", this.buffer_pct);

            // Wait for image and annotations to load
            await image_promise
            
            if (reload_ulabel) {
                // Set new image
                this.ulabel.swap_frame_image(this.rgb_image_url);
                this.rgb_displayed = true;
                // Set new annotations
                this.ulabel.set_annotations(this.preproc_annotations(this.annotations), "plant_counting");
                this.reload_ulabel();
            } else {
                // Start ULabel
                this.startULabel();
            }
        },

        /**
         * Reload ULabel with a new subimage
         */
        async reload_ulabel() {
            // Update subimage qa status
            this.display_qa_status();

            // Hack to update navbar div 
            document.getElementById("navbar-p").innerHTML = "Subimage " + (this.subimage_idx + 1) + " of " + this.n_subimages + ", " + this.image_name + " &nbsp";

            let subimage_crop = get_subimage_crop_from_idx(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_idx,
                this.buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)

            // Update ULabel with new subimage bounds
            this.ulabel.set_annotations([subimage_anno], "subimage_bounds");
            this.ulabel["config"]["initial_crop"] = ulabel_initial_crop;
            this.ulabel.show_initial_crop();
        },

        read_size_cookie() {
            let subtask_name = "plant_counting"
            let cookie_name = subtask_name + "_size=";
            let cookie_array = document.cookie.split(";");
            for (let i = 0; i < cookie_array.length; i++) {
                let current_cookie = cookie_array[i];
                //while there's whitespace at the front of the cookie, loop through and remove it
                while (current_cookie.charAt(0) == " ") {
                    current_cookie = current_cookie.substring(1);
                }
                if (current_cookie.indexOf(cookie_name) == 0) {
                    return parseFloat(current_cookie.substring(cookie_name.length, current_cookie.length))
                }
            }
            return null
        },

        async get_image() {
            // Get keypoints
            let params = {
                field_json: this.field_json,
                image_name: this.image_name,
                annotation_dir: "annotations"
            };
            let [rgb_image_url, annotations] = await get_image_and_annotations(params);
            this.rgb_image_url = rgb_image_url;
            this.annotations = annotations;

            // Get rows
            params.annotation_dir = "row_annotations";
            [rgb_image_url, annotations] = await get_image_and_annotations(params);
            this.row_annotations = annotations;

            // Get image height and width
            let img = await getMeta(this.rgb_image_url);
            this.image_height = img.height;
            this.image_width = img.width;

            var ext = this.image_name.split(".").slice(-1)[0]
            params = {
                field_json: this.field_json,
                image_name: this.image_name.replace(ext, "png"),
                binary_set: this.$store.state.$binary_set
            }

            this.binary_image_url = await get_binary_url(this.field_json, this.image_name, this.$store.state.$binary_set);
        },
        async on_submit(annotations) {
            // Get slider values
            let slider_values = get_slider_values_from_ulabel_html();

            console.log(annotations);
            annotations = this.preproc_annotations(annotations);
            // Semantics to keep formatting consistent.
            let keypoint_post_annotations_params = {
                annotations_json: {
                    annotations: {
                        main: [annotations["annotations"]["plant_counting"]],
                    },
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json,
                subimage_idx: this.subimage_idx,
                keypoint_slider: slider_values.keypoint_confidence_slider_val,
            };
            // Add row distance slider value if it exists
            if (slider_values.row_dist_slider_val) {
                keypoint_post_annotations_params["row_dist_slider"] = slider_values.row_dist_slider_val;
            }

            let row_annotations_params = {
                annotations_json: {
                    "row_annotations": annotations["annotations"]["row_classification"]
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json,
                not_done: true, // Don't mark row qa as done
            }

            // Prepare to navigate to next job.
            let next_idx = this.next_incomplete_subimage();

            // Prevent leave page warning
            this.ulabel.set_saved(true);

            if (next_idx >= this.n_subimages || this.leave_qa_cycle || this.submit_all_subimages) {
                // Only save row annotations if internal user
                if (isInternalUser()) {
                    hit_terraform_api(row_annotations_params, "save_row_qa");
                }

                // If submitting all subimages, approve all subimages
                if (this.submit_all_subimages) {
                    let params = {
                        field_json: this.field_json,
                        update_items: []
                    }
                    for (let subimage_idx = 0; subimage_idx < this.n_subimages; subimage_idx++) {
                        params.update_items.push({
                            "image_name": this.image_name,
                            "qa_type": "keypoint_qa",
                            "qa_val": "approved",
                            "subimage_idx": subimage_idx,
                        }); // approve each subimage
                    }
                    await hit_terraform_api(params, "batch_update_qa");
                }

                // Save keypoint annotations
                await hit_terraform_api(keypoint_post_annotations_params, "save_ppa_qa");

                // If everything occurred as expected, unlock 
                await unlock_image(this.field_json, this.image_name);

                if (this.leave_qa_cycle) {
                    this.navigate_away(); 
                } else {
                    // Navigate to next image
                    await this.load_next_image();
                    this.load_and_run_all();  
                }
            } else {
                // Continuing as normal
                await hit_terraform_api(keypoint_post_annotations_params, "save_ppa_qa");
                if (isInternalUser()) {
                    hit_terraform_api(row_annotations_params, "save_row_qa");
                }

                // Update locally stored annotations
                this.annotations = annotations["annotations"]["plant_counting"];
                console.log("Annotations posted.");

                // Locally update qa_statuses
                this.qa_status[this.subimage_idx]["keypoint_qa"] = "approved";

                // Navigate to next subimage
                this.subimage_idx = next_idx;
                this.$route.params.subimage_idx = next_idx; // Update global param for Navbar
                this.update_store(); // update in case of reject
                this.reload_ulabel();
            }
        },
        next_incomplete_subimage() {
            // Return index of next not_done subimage, or return
            // the total number of subimages so that we progress
            // to the next qa job
            for (let next_idx = (this.subimage_idx + 1).valueOf(); next_idx < this.n_subimages; next_idx++) {
                if (this.qa_status[next_idx]["keypoint_qa"] == "not_done") {
                    return next_idx;
                }
            }
            return this.n_subimages;
        },
        preproc_annotations(annotations) {
            let anno_size = this.read_size_cookie();
            for (var i = 0; i < annotations.length; i++) {
                annotations[i].line_size = anno_size;
            }
            return annotations;
        },
        startULabel() {
            var annos = this.preproc_annotations(this.annotations);

            let subimage_crop = get_subimage_crop_from_idx(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_idx,
                this.buffer_pct
            )
            let ulabel_initial_crop = get_ulabel_initial_crop_from_bbox(...subimage_crop);
            let subimage_anno = make_ulabel_bbox(...subimage_crop)
            let subtasks = {
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(annos),
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(
                    this.row_annotations,
                    {
                        inactive_opacity: 0.0
                    }
                ),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: subimage_bounds_subtask([subimage_anno]),
            };
            var email = get_username(this);
            if (email === undefined) {
                email = "Unauthenticated Stand QA User"
            }

            // Initial ULabel configuration
            let ulabel = new ULabel({
                "container_id": "container",
                "image_data": this.rgb_image_url,
                "username": email,
                "subtasks": subtasks,
                "initial_crop": ulabel_initial_crop,
                "initial_line_size": ULABEL_INITIAL_LINE_SIZE,
                "config_data": get_config_data(this.image_tags, this.field_tags),
                "submit_buttons": this.on_submit,
            });

            this.ulabel = ulabel;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                // ulabel.on(ulabel.config["done_callback"], () => {

                // })
                /*
                So this is absolutely horrible practice to deal with bonkers behavior 
                Basically the .on function in ulabel uses the functions .name property to reassign the callback
                For some reason vue-cli-service build sets all function.name properties to 'value'
                which breaks this callback behavior.
                Manually changing the property like this fixes it for the build version.
                */
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                )
            });

            this.enable_submit();
            // this.disable_submit();
        },
        disable_submit() {
            // Hack to disallow submit before dirtying the page
            document.getElementById("submit").removeAttribute("href");
        },
        enable_submit() {
            // Hack to allow submit before dirtying the page
            // Ensure submit button is always pressable
            var observer = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type === "attributes") {
                        // When button is modified, make sure it can still be pressed
                        if (!document.getElementById("submit").hasAttribute("href")) {
                            document.getElementById("submit").href = "#";
                        }
                    }
                });
            });
            observer.observe(document.getElementById("submit"), {
                attributes: true //configure it to listen to attribute changes
            });
        }
    },
    beforeDestory() {
        this.beforeDestoryHandler();
    }
};
</script>

<style>
#container #toolbox div.keypoint-slider div.keypoint-slider-holder {
    padding: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
}

#container #toolbox div.keypoint-slider div.keypoint-slider-holder span.increment {
    bottom: 0.5em;
}
</style>
