// Loaders for annotations
import EXIF from 'exif-js';
import { get_image_and_annotations_api, get_progress_json } from "./api_endpoints.js"
const { PromisePool } = require('@supercharge/promise-pool')


export function getMeta(url) {
    return new Promise((res, rej) => {
        let img = new Image();
        img.onload = () => res(img);
        img.onerror = () => rej();
        img.src = url;
    });
}


export async function get_image_and_annotations(params) {
    let anno_format_variant_fns = [
        (annos) => {return annos["annotations"]["main"][0]},
        (annos) => {return annos["annotations"]},
        (annos) => {return annos["row_annotations"]},
    ]

    let [rgb_image_url, annotation_json] = await get_image_and_annotations_api(params.field_json, params.annotation_dir, params.image_name);
    console.log(annotation_json);
    let annotations;
    for (let i = 0; i < anno_format_variant_fns.length; i++) {
        try {
            annotations = anno_format_variant_fns[i](annotation_json);
            
            // js doesn't error when accessing a non-existent key, it just returns undefined
            if (annotations == undefined) {
                continue;
            }
            break;
        }
        catch (e) {
            // js WILL error when performing operations on undefined
            continue;
        }
    }
    if (!annotations) {
        throw new Error("Could not parse annotations");
    }
    return [
        rgb_image_url,
        annotations,
    ]
}
