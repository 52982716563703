//API endpoints, consolidated

import { field_json_args, api_consolidator_error_handler } from "./api_utils"


export async function get_all_rgb_filenames(...args) {
    return api_consolidator_error_handler("get_all_rgb_filenames", "filenames", field_json_args(...args))
}

export async function get_progress_json(...args) {
    return api_consolidator_error_handler("get_progress_json", "progress_json", field_json_args(...args))
}

export async function auto_reject_images(field_json, num_images) {
    return api_consolidator_error_handler("auto_reject_images", null, {field_json: field_json, num_images: num_images})
}

export async function get_next_image_to_qa(field_json, image_name, qa_type) {
    return api_consolidator_error_handler("get_next_image_to_qa", "next_image", {field_json: field_json, image_name: image_name, qa_type: qa_type})
}

export async function get_next_outsourced_image_to_qa() {
    return api_consolidator_error_handler("get_next_outsourced_image_to_qa", "next_image", {})
}

export async function get_image_and_annotations_api(field_json, annotation_dir, image_name) {
    return api_consolidator_error_handler("get_image_and_annotations", null, {field_json: field_json, annotation_dir: annotation_dir, image_name: image_name})
}

export async function get_binary_url(field_json, image_name, binary_set) {
    let ext = image_name.split(".").slice(-1)[0];
    if (ext != "png" && ext != "PNG") {
        image_name = image_name.replace(ext, "png");
    }
    return api_consolidator_error_handler("get_binary_url", "url", {field_json: field_json, image_name: image_name, binary_set: binary_set})
}

//////////
// TAGS //
//////////
export async function get_single_image_tags(field_json, image_name) {
    return api_consolidator_error_handler("get_image_tags", "tags", {field_json: field_json, image_name: image_name})
}

export async function get_all_image_tags(...args) {
    return api_consolidator_error_handler("get_all_image_tags", "tags", field_json_args(...args))
}

export async function get_all_field_tags(field_json) { 
    return api_consolidator_error_handler("get_all_field_tags", null, field_json_args(field_json))
}
