<template>
    <div>
        <div
            id="container"
            style="
                width: 100%;
                height: calc(100vh - 66px);
                position: absolute;
                top: 66px;
                left: 0;
            "
        >
        </div>
    </div>
</template>

<script>
import { get_next_image_to_qa } from "@common/app/api_endpoints.js";

export default {
    created() {
        // Immediately redirect to next qa task
        this.getNextImageToQA();
    },

    methods: {
        async getNextImageToQA() {
            let next_image = await get_next_image_to_qa(
                this.$store.state.$field_json,
                "",
                "gender_qa",
            )
            console.log(next_image);

            if (next_image == "Nothing to QA.") {
                // Return to progress
                location.href = "/progress";
            } else {
                // Gender QA, which is just row qa in gender mode
                this.$router.push({
                    name: "Row QA",
                    params: {
                        field_name: this.$store.state.$field_name,
                        image_name: next_image.image_name,
                        gender_qa: true
                    },
                });
            }
        }
    }
};
</script>
