<template>
    <div>
        <div id="navbar-break-div">
            <input type="button" id="navbar-break-button" value="Unlock and Exit" v-on:click="take_a_break()" />
            <input type="button" id="navbar-submit-break-button" value="Submit and Exit" v-on:click="take_a_break(true)"/>
            <input type="button" id="navbar-reject-break-button" value="Reject and Exit" v-on:click="take_a_break(false, true)"/>
        </div>
        <div
            id="container"
            style="
                width: 100%;
                height: calc(100vh - 74px);
                position: absolute;
                top: 74px;
                left: 0;
            "
        ></div>
    </div>
</template>
<script>
import { 
    gendered_row_qa_subtask,
    get_config_data, 
    get_slider_values_from_ulabel_html,
    ppa_qa_subtask,
    subimage_bounds_subtask 
} from "@common/app/ulabel_utils.js";
import { 
    ULABEL_INITIAL_LINE_SIZE, 
    ULABEL_SUBTASK_IDS, 
} from "@common/app/constants.js";
import { ULabel } from "ulabel"
import { extend_line, extend_all }  from "@common/app/ulabel_extend_line";
import { get_image_and_annotations, getMeta } from "@common/app/annotation_loaders.js";
import { hit_terraform_api } from "@common/app/api_utils.js";
import { lock_image, unlock_image, get_username } from "@common/app/qa_utils.js";
import { get_single_image_tags, get_binary_url, get_next_image_to_qa, get_all_field_tags } from "@common/app/api_endpoints.js";
import { get_subimage_qa_status } from "@common/app/ddb_utils.js";
import { make_all_subimage_bboxes } from "@common/app/ulabel_anno_utils.js";

export default {
    data: function () {
        return {
            field_name: "",
            image_name: "",
            ulabel: null,
            rgb_image_url: "",
            annotations: "",
            keypoint_annotations: "", 
            binary_image_url: "", 
            rgb_displayed: true,
            field_json: null,
            leave_qa_cycle: false,
            image_tags: "",
            gender_qa: false,
            grid: false, // Whether to redirect to grid
            n_h_subimages: 3,
            n_w_subimages: 3,
            n_subimages: 9,
            image_height: 0,
            image_width: 0,
            opened_from_grid_as_new_tab: false
        };
    },
    async mounted() {
        // Add event listeners
        document.addEventListener("keydown", this.keydownEventHandler);

        this.field_json = this.$store.state.$field_json;
        this.field_name = this.$store.state.$field_name;
        
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "gender_qa")) {
            this.gender_qa = this.$route.params.gender_qa;
        } else if (Object.prototype.hasOwnProperty.call(this.$route.query, "grid") && this.$route.query.grid == "true") {
            this.opened_from_grid_as_new_tab = true;
            // Copy relevant data from query to params
            this.$route.params.image_name = this.$route.query.image_name;
            this.$route.params.grid = this.$route.query.grid;
            this.$route.params.gender_qa = this.$route.query.gender_qa;
        }  
        
        if (Object.prototype.hasOwnProperty.call(this.$route.params, "image_name")) { 
            this.image_name = this.$route.params.image_name;
        } else {  
            // If no image_name is in the route params, we get the next available
            await this.load_next_image();
        }

        // Build top buttons
        this.build_break_button();
        
        this.load_and_run_all();
    },
    methods: {
        keydownEventHandler(e) {
            extend_line(e, this.ulabel, "row_classification");
            extend_all(e, this.ulabel, "row_classification");
            if (e.key == "b") {
                if (this.rgb_displayed) {
                    this.ulabel.swap_frame_image(this.binary_image_url);
                } else {
                    this.ulabel.swap_frame_image(this.rgb_image_url);
                }
                this.rgb_displayed = !this.rgb_displayed;
            }
            if (e.key == "Enter") {
                document.getElementById("submit").click();
            }
        },
        build_break_button() {
            // Attach the buttons to the navbar
            var navbar = document.getElementById("controls-navbar");
            navbar.appendChild(document.getElementById("navbar-break-div"));
        },
        beforeDestoryHandler() {
            // Remove event listeners (including ULabel's)
            document.removeEventListener("keydown", this.keydownEventHandler);
            if (this.ulabel) {
                this.ulabel.remove_listeners();
            }
            // Remove the buttons
            document.getElementById("navbar-break-div").remove();
        },
        navigate_away(reject = false) {
            this.beforeDestoryHandler();
            
            if (this.opened_from_grid_as_new_tab) {
                // Close new tab, no need to navigate
                return window.close();
            }

            let dest = reject ? "RejectRow" : "Progress"
            this.$router.push({
                name: dest,
            });
        },
        async take_a_break(submit = false, reject = false) {
            if (submit) {
                this.leave_qa_cycle = true;
                document.getElementById("submit").click();
            } else if (reject) {
                // Normal reject workflow, but will return to progress after
                this.$store.commit("update$break", true);
                this.navigate_away(true);
            } else {
                // Unlock image and return to progress
                await unlock_image(this.field_json, this.image_name);
                this.navigate_away();
            }
        },
        async load_next_image() {
            // If no image_name is in the route params, we get the next available
            let next_image = await get_next_image_to_qa(
                this.field_json,
                "",
                this.gender_qa ? "gender_qa" : "row_qa",
            )
            
            console.log(next_image)
            if (next_image == "Nothing to QA.") {
                this.navigate_away();
            }
            this.image_name = next_image.image_name;
            // Update the displayed image name
            document.getElementById("navbar-p").innerHTML = "&nbsp&nbsp" + this.image_name;
        },
        async load_and_run_all() {
            // Start async tasks
            let lock_acquired_promise = lock_image(this.field_json, this.image_name);
            let image_tags_promise = get_single_image_tags(this.field_json, this.image_name);
            let field_tags_promise = get_all_field_tags(this.field_json);
            let subimage_statuses_promise = get_subimage_qa_status(
                this.field_name, this.field_json, this.image_name
            );
           
            if (!await lock_acquired_promise) {
                alert("Image is locked by another user. Please try again later.");
                this.navigate_away();
            }

            this.field_tags = await field_tags_promise;
            try {
                this.buffer_pct = parseInt(this.field_tags["image_buffer"]["value"]);
            } catch (e) {
                console.log("Error getting buffer_pct:", e);
            }
            console.log("Buffer pct:", this.buffer_pct)

            // Store values in case of reject
            this.$store.commit("update$image_name", this.image_name);
            this.$store.commit("update$gender_qa", this.gender_qa);
            
            // Get subimage status to properly color subimages
            this.subimage_qa_status = await subimage_statuses_promise["subimages"];
            this.image_tags = await image_tags_promise;

            // Load the image and annotations
            await this.get_image_and_row_annotations();

            // Start ULabel
            this.start_ULabel();
        },
        async get_image_and_row_annotations() {
            var params = {
                field_name: this.field_name,
                field_json: this.field_json,
                image_name: this.image_name,
                annotation_dir: "row_annotations"
            }
            let [rgb_image_url, annotations] = await get_image_and_annotations(params);
            this.rgb_image_url = rgb_image_url;
            this.annotations = annotations;

            // Get image height and width
            let img = await getMeta(this.rgb_image_url);
            this.image_height = img.height;
            this.image_width = img.width;
            
            // If we didn't load keypoint_annotations from redirect, use these
            if (this.keypoint_annotations == "") {
                params.annotation_dir = "annotations";
                [rgb_image_url, annotations] = await get_image_and_annotations(params);
                this.keypoint_annotations = annotations;
            }

            this.binary_image_url = await get_binary_url(this.field_json, this.image_name, this.$store.state.$binary_set);
        },
        async on_submit(annotations) {
            console.log(annotations);
            let post_annotations_params = {
                annotations_json: {
                    "row_annotations": annotations["annotations"]["row_classification"]
                },
                image_name: this.image_name,
                field_name: this.field_name,
                field_json: this.field_json, 
                reject: false,
                qa_type: this.gender_qa ? "gender_qa" : "row_qa"
            }
            await hit_terraform_api(post_annotations_params, "save_row_qa");

            let slider_values = get_slider_values_from_ulabel_html();
            post_annotations_params = {
                annotations_json: {
                    annotations: {
                        main: [annotations["annotations"]["plant_counting"]],
                    },
                },
                image_name: this.image_name,
                field_json: this.field_json, 
                subimage_idx: -1,
                keypoint_slider: slider_values.keypoint_confidence_slider_val,
                row_distance_slider: slider_values.row_dist_slider_val,
            };
            await hit_terraform_api(post_annotations_params, "save_ppa_qa");

            // Prevent leave page warning
            this.ulabel.set_saved(true)

            // If everything occurred as expected, unlock 
            await unlock_image(this.field_json, this.image_name);

            if (this.leave_qa_cycle || this.opened_from_grid_as_new_tab) {
                // Leave QA cycle
                return this.navigate_away();
            }
            
            // Continue to next image
            await this.load_next_image();
            this.load_and_run_all();
        },
        start_ULabel() {
            let annos = this.annotations;
            console.log(annos);
            let subimage_bbox_annos = make_all_subimage_bboxes(
                this.n_w_subimages,
                this.n_h_subimages,
                this.image_width,
                this.image_height,
                this.subimage_qa_status,
                this.buffer_pct,
                0.995, // resize_factor
            )
            let subtasks = {
                [ULABEL_SUBTASK_IDS.ROW_QA]: gendered_row_qa_subtask(annos),
                [ULABEL_SUBTASK_IDS.PLANT_COUNT_QA]: ppa_qa_subtask(this.keypoint_annotations),
                [ULABEL_SUBTASK_IDS.SUBIMAGE_BOUNDS]: subimage_bounds_subtask(subimage_bbox_annos, {inactive_opacity: 0.5}),
            };

            var email = get_username(this);
            if (email === undefined) {
                email = "Unauthenticated Stand QA User"
            }
            
            // Initial ULabel configuration
            let ulabel = new ULabel({
                "container_id": "container",
                "image_data": this.rgb_image_url,
                "username": email,
                "subtasks": subtasks,
                "initial_line_size": ULABEL_INITIAL_LINE_SIZE,
                "config_data": get_config_data(this.image_tags, this.field_tags),
                "submit_buttons": this.on_submit,
            });
            this.ulabel = ulabel;

            // Wait for ULabel instance to finish initialization
            ulabel.init(function () {
                Object.defineProperties(
                    ulabel.finish_annotation,
                    {
                        name:
                        {
                            value: "finish_annotation",
                            writable: true,
                        }
                    }
                );
            });
        },
    },
};
</script>
